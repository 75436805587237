import _ from "lodash";
import axios from "axios";
import React from "react";
import ReactCalendar from "react-calendar";
import { Divider } from "semantic-ui-react";
import { Icon, Link, Popup, Heading, Message, EmptyState } from ".";

class FloatingMenu extends React.Component {
    state = {
        eventPopup: false,
        prayerTime: {},
        prayerTimePopup: false
    };

    componentDidMount() {
        const date = new Date();
        let day = date.getDate();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;

        if (day < 10) day = `0${day}`;
        if (month < 10) month = `0${month}`;

        const params = {
            r: "esolatApi/takwimsolat",
            zone: "WLY01",
            date: [year, month, day].join("-"),
            period: "date"
        };

        axios.get("https://www.e-solat.gov.my/index.php", { params }).then(o => {
            const { data } = o;
            const { status, prayerTime = [] } = data;

            if (status === "OK!" && Object.keys(prayerTime).length > 0) {
                this.setState({ prayerTime: _.get(prayerTime, "[0]", {}) });
            }
        });
    }

    _togglePrayerTimes = () => {
        this.setState({ prayerTimePopup: !this.state.prayerTimePopup });
    };

    _toggleEventPopup = () => {
        this.setState({ eventPopup: !this.state.eventPopup });
    };

    render() {
        const { settings } = this.props;

        const floatingButtons = _.get(settings, "floating_buttons", []);
        const hasButtons = _.isArray(floatingButtons) && floatingButtons.length > 0;
        const callbacks = {
            _toggleEventPopup: this._toggleEventPopup,
            _togglePrayerTimes: this._togglePrayerTimes
        };

        return (
            hasButtons && (
                <React.Fragment>
                    <div className="aibim floating-menu">
                        {floatingButtons.map((item, index) => {
                            let { to, onClick, icon, pulsating = false } = item;

                            if (typeof onClick === "string" && onClick.length > 0) {
                                to = "";
                                onClick = _.get(callbacks, onClick, {});
                            }

                            if (typeof to !== "undefined" && to !== "") {
                                return (
                                    <Link to={to} key={index} className="item">
                                        <Icon name={icon} />
                                        {pulsating && <div className="om pulsating red dot"></div>}
                                    </Link>
                                );
                            } else {
                                return (
                                    <div key={index} onClick={onClick} className="item">
                                        <Icon name={icon} />
                                        {pulsating && <div className="om pulsating red dot"></div>}
                                    </div>
                                );
                            }
                        })}
                    </div>

                    <EventPopup state={this.state} onClose={this._toggleEventPopup} />
                    <PrayerTimes state={this.state} onClose={this._togglePrayerTimes} />
                </React.Fragment>
            )
        );
    }
}

class EventPopup extends React.Component {
    state = {
        date: null,
        events: [],
        loading: true,
        currentEvents: []
    };

    componentDidMount() {
        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        const { fn } = window;

        fn.tokenize("get_events").then(token => {
            fn.get("/get_events", { params: { token } }).then(o => {
                const { data } = o;

                if (_.isArray(data) && data.length > 0) {
                    this.setState({
                        events: data,
                        loading: false,
                        currentEvents: this._findEventsThisMonth(currentMonth, data)
                    });
                } else {
                    this.setState({ loading: false });
                }
            });
        });

        this.setState({ date });
    }

    _onChangeDate = ({ activeStartDate }) => {
        const date = new Date(activeStartDate);

        if (typeof date !== "undefined" && date instanceof Date) {
            const currentMonth = date.getMonth() + 1;
            this.setState({
                currentEvents: this._findEventsThisMonth(currentMonth)
            });
        }
    };

    _findEventsThisMonth = (currentMonth, events) => {
        if (typeof events === "undefined" || !_.isArray(events)) events = this.state.events;
        return _.filter(events, o => o.month === currentMonth) || [];
    };

    render() {
        const { state, onClose } = this.props;
        const { eventPopup } = state;
        const { date, events, loading, currentEvents } = this.state;
        const buttons = [
            {
                text: "Close",
                color: "blue",
                onClick: onClose,
                transparent: true
            }
        ];

        const kicker = {
            fontSize: 10,
            fontWeight: "bold",
            marginBottom: _.isArray(currentEvents) && currentEvents.length > 0 ? -20 : 10,
            textTransform: "uppercase"
        };

        // let displayDate = "This Month";
        // let currentMonth = null;
        // let currentYear = null;
        // if (loading === false) {
        //     currentMonth = fn.getMonthFullName(date.getMonth());
        //     currentYear = date.getFullYear();
        //     displayDate = `${currentMonth} ${currentYear}`;
        // }

        return (
            <Popup size="tiny" open={eventPopup} buttons={buttons} onClose={onClose}>
                <Heading as="h5" icon="far calendar-alt" text="Events Calendar" className="blue" />

                <ReactCalendar
                    value={date}
                    onActiveDateChange={this._onChangeDate}
                    minDetail="month"
                    className="clean"
                    prevLabel={<Icon icon="far angle-left" />}
                    nextLabel={<Icon icon="far angle-right" />}
                    prev2Label={<Icon icon="far angle-double-left" />}
                    next2Label={<Icon icon="far angle-double-right" />}
                    tileContent={({ date, view }) => {
                        if (view === "month") {
                            const currentDay = date.getDate();
                            const currentMonth = date.getMonth() + 1;
                            const currentYear = date.getFullYear();

                            const index = _.findIndex(events, o => {
                                const { day, month, year } = o;
                                const presentDay = _.isArray(day)
                                    ? day.indexOf(currentDay) > -1
                                    : day === currentDay;

                                return presentDay && month === currentMonth && year === currentYear;
                            });

                            if (index > -1) return <div className="om red dot" />;
                        }

                        return null;
                    }}
                />

                <Divider hidden />
                <div style={kicker}>Happening This Month:</div>
                {loading === false && <EventPopup.CurrentEvents data={currentEvents} />}
            </Popup>
        );
    }

    static CurrentEvents({ data }) {
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const { fn } = window;

        if (_.isArray(data) && data.length) {
            return data.map((item, index) => {
                let displayDate = "";
                let { name, link, day, month, year } = item;

                if (_.isArray(day)) {
                    const firstDay = _.head(day);
                    const lastDay = _.last(day);
                    let fDate = new Date(year, month - 1, firstDay).getDay() - 1;
                    let lDate = new Date(year, month - 1, lastDay).getDay() - 1;

                    if (lDate === -1) {
                        lDate = 6;
                    }

                    if (fDate === -1) {
                        fDate = 6;
                    }

                    const firstDayName = _.get(days, `[${fDate}]`);
                    const lastDayName = _.get(days, `[${lDate}]`);
                    
                    displayDate = `${firstDayName}-${lastDayName}, ${firstDay}-${lastDay} ${fn.getMonthFullName(
                        month-1
                    )} ${year}`;
                } else {
                    const date = new Date(year, month - 1, day);
                    displayDate = date.toLocaleDateString("en-US", {
                        day: "numeric",
                        year: "numeric",
                        month: "long",
                        weekday: "long"
                    });
                }

                if (typeof link !== "undefined" && link !== "") {
                    return (
                        <Link to={link} key={index}>
                            <Heading
                                as="h6"
                                text={name}
                                className="blue text"
                                subtitle={displayDate}
                            />
                        </Link>
                    );
                } else {
                    return (
                        <Heading
                            as="h6"
                            key={index}
                            text={name}
                            className="blue text"
                            subtitle={displayDate}
                        />
                    );
                }
            });
        } else {
            return <Message text="No event is happening this month." style={{ marginBottom: 0 }} />;
        }
    }
}

class PrayerTimes extends React.Component {
    render() {
        const { state, onClose } = this.props;
        const { prayerTime, prayerTimePopup } = state;

        const labels = {
            fajr: "Subuh",
            syuruk: "Syuruk",
            dhuhr: "Zuhur",
            asr: "Asar",
            maghrib: "Maghrib",
            isha: "Isha'"
        };

        let prayers = [];
        _.each(Object.keys(_.pick(prayerTime, Object.keys(labels))), key => {
            let name = _.get(labels, key, "");
            let time = _.get(prayerTime, key, "").split(":");

            if (time.length > 0 && name.length > 0) {
                let hour = _.get(time, "[0]", 0);
                const minute = _.get(time, "[1]", 0);
                const isPM = hour > 12 || (hour >= 12 && minute > 0);
                const meridiem = isPM ? "pm" : "am";

                hour = Math.abs(isPM ? 12 - hour : hour);
                time = `${hour}:${minute}`;
                prayers = [...prayers, { name, time, meridiem }];
            }
        });

        const buttons = [
            {
                size: "small",
                text: "Close",
                color: "blue",
                onClick: onClose,
                transparent: true
            }
        ];

        return (
            <Popup
                size="mini"
                open={prayerTimePopup}
                onClose={onClose}
                buttons={buttons}
                closeOnEscape
                closeOnDimmerClick
            >
                <Heading
                    as="h5"
                    icon="far moon"
                    text="Prayer Times"
                    subtitle="Wilayah Persekutuan Kuala Lumpur"
                />
                <PrayerTimes.Prayers prayers={prayers} />
            </Popup>
        );
    }

    static Prayers(props) {
        const { prayers = [] } = props;

        if (_.isArray(prayers) && prayers.length > 0) {
            return (
                <div className="aibim prayer-times">
                    {prayers.map((prayer, index) => {
                        const { name, time, meridiem } = prayer;

                        return (
                            <div key={index} className="row">
                                <div className="label">{name}</div>
                                <div className="value">
                                    {time} <small className="light text">{meridiem}</small>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <EmptyState
                    width={200}
                    title="Prayer times are unavailable"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                    artName="undraw_moonlight_5ksn.svg"
                />
            );
        }
    }
}

export { FloatingMenu };
