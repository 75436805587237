import _ from "lodash";
import React from "react";
import { Grid } from "semantic-ui-react";
import { Button, Heading, MiniNews, CardContent } from "..";

class SectionPrimary extends React.Component {
    state = {
        news: [],
        newsLoading: true
    };

    componentDidMount() {
        const { fn } = window;

        fn.tokenize("get_news_list").then(token => {
            fn.get("/get_news_list", { params: { token } }).then(o => {
                let state = { newsLoading: false };
                const { data } = o;

                if (_.isArray(data) && data.length > 0) {
                    state = { ...state, news: data };
                }
                this.setState(state);
            });
        });
    }

    render() {
        const { news, newsLoading } = this.state;
        const { settings } = this.props;
        const { videos } = settings;
        const link = _.get(videos, "[0].value", "https://www.youtube.com/embed/d2UuDCgHxMg");

        return (
            <CardContent>
                <Grid className="equal height" stackable>
                    <Grid.Column width={6}>
                        <Heading
                            as="h4"
                            text="Latest News"
                            subtitle="Latest news around the Islamic Finance."
                            className="blue"
                        />
                        <MiniNews news={news} loading={newsLoading} style={{ maxHeight: 350 }} />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <div className="aibim video">
                            <iframe
                                src={link}
                                title="video"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope"
                                className="youtube"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>

                        <Button
                            to="/videos"
                            icon="far arrow-right"
                            text="Watch more videos"
                            color="blue"
                            transparent
                        />
                    </Grid.Column>
                </Grid>
            </CardContent>
        );
    }
}

export { SectionPrimary };
