import _ from "lodash";
import React from "react";
import Swiper from "nuka-carousel";
import PropTypes from "prop-types";
import { Dimmer, Loader } from "semantic-ui-react";
import { Icon, Link } from ".";

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0
        };
    }

    _centerLeftControl = ({ previousSlide }) => {
        let controlStyle = {};
        const { bottomOffset } = this.props;

        if (typeof bottomOffset !== "undefined")
            controlStyle = { ...controlStyle, marginTop: -bottomOffset };

        return (
            <div className="control" style={controlStyle} onClick={previousSlide}>
                <Icon name="far chevron-left" />
            </div>
        );
    };

    _centerRightControl = ({ nextSlide }) => {
        let controlStyle = {};
        const { bottomOffset } = this.props;

        if (typeof bottomOffset !== "undefined")
            controlStyle = { ...controlStyle, marginTop: -bottomOffset };

        return (
            <div className="control" style={controlStyle} onClick={nextSlide}>
                <Icon name="far chevron-right" />
            </div>
        );
    };

    _bottomCenterControl = e => {
        let dotStyle = {};
        const { bottomOffset } = this.props;
        const { slideCount, goToSlide, currentSlide } = e;

        // Padding Bottom for the Dots
        if (typeof bottomOffset !== "undefined")
            dotStyle = { ...dotStyle, paddingBottom: bottomOffset };

        return (
            <div className="dots" style={dotStyle}>
                {_.times(slideCount, i => {
                    const active = i === currentSlide;
                    const onClick = !active ? () => goToSlide(i) : null;
                    const className = active ? "active dot" : "dot";

                    return <div key={i} onClick={onClick} className={className} />;
                })}
            </div>
        );
    };

    render() {
        let containerStyle = {};
        const props = _.omit(this.props, OMITTED_PROPS);
        const { data, width = 400, height = 400, style, loading } = this.props;

        if (typeof this.carousel !== "undefined") console.log(this.carousel.swipe.getPos());

        // Set the width and height when necessary
        if (typeof width !== "undefined") containerStyle = { ...containerStyle, width };
        if (typeof height !== "undefined") containerStyle = { ...containerStyle, height };

        // When stylesheet is set, merge with the containerStyle
        if (typeof style === "object") containerStyle = { ...containerStyle, ...style };

        if (loading === true) {
            return (
                <div className="om carousel" style={containerStyle}>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                </div>
            );
        } else {
            return (
                <Swiper
                    style={containerStyle}
                    className="om carousel"
                    wrapAround={true}
                    autoplayInterval={6000}
                    renderCenterLeftControls={this._centerLeftControl}
                    renderCenterRightControls={this._centerRightControl}
                    renderBottomCenterControls={this._bottomCenterControl}
                    {...props}
                >
                    {data.map((slide, index) => {
                        const { url, src, alt, overlay = false } = slide;

                        if (typeof url !== "undefined" && url.length > 0) {
                            return (
                                <div key={index}>
                                    <Link to={url}>
                                        <img alt={alt} src={src} />
                                        {overlay && <div className="overlay" />}
                                    </Link>
                                </div>
                            );
                        }
                        return (
                            <div key={index}>
                                <img alt={alt} src={src} />
                                {overlay && <div className="overlay" />}
                            </div>
                        );
                    })}
                </Swiper>
            );
        }
    }
}

const OMITTED_PROPS = ["ref", "width", "height", "style", "loading", "className"];

Carousel.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    loading: PropTypes.bool,
    bottomOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export { Carousel };
