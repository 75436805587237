import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Heading, Carousel } from ".";

class Header extends React.Component {
    render() {
        const { title, subtitle, featured = false, settings } = this.props;
        const { headers = [] } = settings || {};

        const rest = _.omit(this.props, [
            "title",
            "subtitle",
            "width",
            "height",
            "featured",
            "settings"
        ]);

        if (featured === true)
            return <Carousel width="100%" height={540} data={headers} {...rest} />;
        else
            return (
                <div className="aibim header">
                    <Heading as="h2" text={title} subtitle={subtitle} />
                </div>
            );
    }
}

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    featured: PropTypes.bool
};

export { Header };
