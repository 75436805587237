import _ from "lodash";
import * as actions from "../actions";
import React from "react";
import parse from "url-parse";
import { connect } from "react-redux";
import { Grid, Loader } from "semantic-ui-react";
import {
    RelLinks,
    Global,
    Header,
    Content,
    Markdown,
    CardContent,
    SectionFooter
} from "../components";

class Subpage extends React.Component {
    state = {
        init: false,
        data: {},
        loading: true,
        viewable: true,
        currentSlug: ""
    };

    componentDidUpdate() {
        const path = _.get(this.props, "match.path", "");
        const params = _.get(this.props, "match.params", {});
        const { loading, currentSlug } = this.state;
        const { slug, page } = params;
        const { fn } = window;

        const isNews = path === "/news/:slug";
        const id = isNews ? slug : page;
        const endpoint = isNews ? "get_news" : "get_page";

        if (id !== currentSlug) {
            if (loading === false) this.setState({ loading: true });

            fn.tokenize(endpoint).then(token => {
                const params = { token, slug: id };

                fn.get(`/${endpoint}`, { params }).then(o => {
                    const { data } = o;
                    const { title = "" } = data;
                    const emptyState = {
                        viewable: false,
                        data: {
                            title: "404 Not Found",
                            content:
                                "#### Content not found.\nThe content that you are looking for cannot be found on our website."
                        }
                    };

                    const state = title.length > 0 ? { data } : emptyState;

                    this.setState({ ...state, loading: false, currentSlug: id });
                });
            });
        }
    }

    _imageRender = props => {
        const actualProps = _.omit(props, ["src", "alt"]);

        const { CONST } = window;
        const { API_URL, IS_DEV } = CONST;
        const { src, alt } = props;
        const { currentSlug } = this.state;

        const parseUrl = parse(API_URL, true);
        const { protocol, host } = parseUrl;
        const baseUrl = IS_DEV ? `${protocol}//${host}` : "";
        const imgSource = `${baseUrl}/media/pages/${currentSlug}/${src}`;

        return <img src={imgSource} alt={alt} {...actualProps} />;
    };

    render() {
        const { data } = this.state;
        const { settings = {} } = this.props.data;
        const { footnote = {} } = settings;
        const { title, subtitle = "", parentTitle = "" } = data;

        return (
            <Global title={title}>
                <Header title={parentTitle || title} subtitle={subtitle} />

                <Content footerLeft={footnote.left} footerRight={footnote.right}>
                    <CardContent>
                        <Subpage.Content
                            data={data}
                            state={this.state}
                            imageRender={this._imageRender}
                        />
                    </CardContent>

                    <SectionFooter settings={settings} />
                </Content>
            </Global>
        );
    }

    static Content(props) {
        let links = [];
        const { data, state, imageRender } = props;
        const { rel, title, content, relTitle = "" } = data;
        const { loading, currentSlug } = state;

        _.each(rel, item => {
            const match = item.match(/\((.*?)\)/);
            const link = _.get(match, "[1]", "");
            const active = link === `/${currentSlug}`;

            links = [...links, { text: item, active }];
        });

        if (loading === false) {
            if (_.isArray(rel) && rel.length > 0) {
                return (
                    <Grid stackable>
                        <Grid.Column width={5}>
                            <RelLinks title={relTitle || title} links={links} />
                        </Grid.Column>

                        <Grid.Column width={11}>
                            <Markdown
                                source={content}
                                escapeHtml={false}
                                renderers={{ image: imageRender }}
                            />
                        </Grid.Column>
                    </Grid>
                );
            } else {
                return (
                    <Markdown
                        source={content}
                        escapeHtml={false}
                        renderers={{ image: imageRender }}
                    />
                );
            }
        } else {
            return <Loader active inline="centered" />;
        }
    }
}

const mapStateToProps = state => state;
export default connect(
    mapStateToProps,
    actions
)(Subpage);
