import api from "../api";
import fn from "../includes/Functions";
import CONST from "../includes/Constants";
const { R } = CONST;

/**
 * A sample request made from backend's API.
 *
 * @return  object
 * @since   1.0
 */
export const sampleRequest = () => (dispatch, getState) => {
    fn.tokenize("test").then(async token => {
        await api.get("/test", { params: { token } }).then(o =>
            dispatch({
                type: R.SAMPLE_REQUEST,
                payload: o.data
            })
        );
    });
};

/**
 * Set progress bar.
 *
 * @param   boolean     isOpen  Set to true if we're showing the progress bar.
 *
 * @return  object
 * @since   1.0
 */
export const setProgressBar = isOpen => ({
    type: R.SET_PROGRESS_BAR,
    isOpen: isOpen
});

/**
 * Set the current page.
 *
 * @param   string     path     The page path (usually the root).
 *
 * @return  object
 * @since   1.0
 */
export const setCurrentPage = path => ({
    type: R.SET_CURRENT_PAGE,
    payload: path
});

/**
 * Set alert.
 *
 * @param   string      content     The alert content.
 * @param   string      color       The alert color.
 * @param   integer     duration    The time before the alert disappears. Set to 0 to make it stay.
 * @param   integer     tempo       The duration for the alert animation.
 * @param   string      animation   The animation type.
 *
 * @return  object
 * @since   0.1
 */
export const newAlert = (
    content,
    color,
    animation = "fade left",
    duration = CONST.ALERT_DURATION,
    tempo = CONST.ALERT_TEMPO
) => {
    return {
        type: R.NEW_ALERT,
        payload: {
            id: fn.unique(),
            show: true,
            color,
            tempo,
            content,
            duration,
            animation
        }
    };
};

/**
 * Hide unused alert popup from must associate with fn.hideAlert() for efficiency.
 *
 * @param   object  array   New data.
 *
 * @return  object
 * @since   0.1
 */
export const hideAlert = data => {
    return {
        type: R.HIDE_ALERT,
        payload: data
    };
};

/**
 * Delete all alerts.
 *
 * @return  object
 * @since   0.1
 */
export const truncateAlert = () => {
    return {
        type: R.TRUNCATE_ALERT,
        payload: []
    };
};

/**
 * Set the menu array.
 *
 * @return  object
 * @since   0.1
 */
export const setMenuArray = () => dispatch => {
    fn.tokenize("get_menu").then(async token => {
        const params = { token };
        await api.get("/get_menu", { params }).then(o => {
            const { data } = o;

            if (typeof data === "object" && data.constructor === Array) {
                dispatch({
                    type: R.SET_MENU_ARRAY,
                    payload: data
                });
            }
        });
    });
};

/**
 * Clear menu array.
 *
 * @return  object
 * @since   0.1
 */
export const clearMenuArray = () => {
    return {
        type: R.CLEAR_MENU_ARRAY,
        payload: []
    };
};

/**
 * Set the user data.
 *
 * @see     Login.js
 * @return  object
 * @since   0.1
 */
export const getCurrentUser = () => dispatch => {
    fn.tokenize("get_current_user").then(async token => {
        const params = { token };
        await api.get("/get_current_user", { params }).then(o => {
            const { data } = o;

            if (typeof data === "object" && Object.keys(data).length > 0) {
                dispatch({
                    type: R.GET_USER_DATA,
                    payload: data
                });
            }
        });
    });
};

/**
 * Turn on/of the popup ads.
 *
 * @since   0.1
 */
export const enableAds = bool => {
    return {
        type: R.ENABLE_ADS,
        payload: bool
    };
};

/**
 * Get settings.
 *
 * @see     GlobalRedux.js
 * @return  object
 * @since   0.1
 */
export const getSettings = () => dispatch => {
    fn.tokenize("get_settings").then(async token => {
        await api.get("/get_settings", { params: { token } }).then(o => {
            const { data } = o;

            dispatch({
                type: R.GET_SETTINGS,
                payload: data
            });
        });
    });
};

/**
 * Get bank members.
 *
 * @see     GlobalRedux.js
 * @return  object
 * @since   0.1
 */
export const getMemberBanks = () => dispatch => {
    fn.tokenize("get_member_banks").then(async token => {
        await api.get("/get_member_banks", { params: { token } }).then(o => {
            const { data } = o;

            dispatch({
                type: R.GET_MEMBER_BANKS,
                payload: data
            });
        });
    });
};
