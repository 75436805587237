import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Link, Markdown } from ".";

class MainMenu extends React.Component {
    render() {
        const { fn } = window;
        const { menu } = this.props;

        if (_.isArray(menu) && menu.length > 0) {
            let array = [];
            _.each(menu, props => {
                const { to, label } = props;
                const item = { source: `[${label}](${to})`, ...props };

                array = [...array, item];
            });

            return (
                <div className="aibim menu">
                    <ul>
                        {array.map((props, key) => {
                            const { to, alt = "image", source, label, image, children } = props;
                            const rest = _.omit(props, ["to", "label", "source", "children"]);
                            const allowed = ["text", "link"];
                            const hasSource = typeof source !== "undefined" && source.length > 0;
                            const mdProps = {
                                source,
                                allowedTypes: allowed,
                                unwrapDisallowed: true,
                                ...rest
                            };

                            if (hasSource) {
                                return (
                                    <li key={key} className={image ? "with-image" : ""}>
                                        {fn.renderif(
                                            typeof image !== "undefined",
                                            <Link to={to}>
                                                <img src={image} alt={alt} />
                                            </Link>,
                                            <Markdown {...mdProps} />
                                        )}
                                        {children && (
                                            <Submenu children={children} allowed={allowed} />
                                        )}
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={key}>
                                        <div>
                                            {fn.renderif(
                                                typeof image !== "undefined",
                                                <img src={image} alt={alt} />,
                                                label
                                            )}
                                        </div>
                                        {children && (
                                            <Submenu children={children} allowed={allowed} />
                                        )}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            );
        } else {
            return <div className="aibim menu" />;
        }
    }
}

const Submenu = ({ children, allowed }) => {
    if (_.isArray(children) && children.length > 0) {
        return (
            <div className="submenu">
                <ul>
                    {children.map((child, i) => {
                        const { to, label } = child;
                        const rest = _.omit(child, ["to", "child"]);
                        const link =
                            typeof to !== "undefined" && to.length > 0
                                ? `[${label}](${to})`
                                : label;

                        if (typeof label === "undefined" || label.length < 1) return "";

                        return (
                            <li key={i}>
                                <Markdown
                                    source={link}
                                    allowedTypes={allowed}
                                    unwrapDisallowed
                                    {...rest}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    } else {
        return "";
    }
};

MainMenu.propTypes = {
    menu: PropTypes.array
};

export { MainMenu };
