import _ from "lodash";
import * as actions from "../actions";
import React from "react";
import { connect } from "react-redux";
import { Grid, Loader, Divider } from "semantic-ui-react";
import {
    Link,
    Card,
    Button,
    Global,
    Header,
    Heading,
    Content,
    CardCover,
    CardContent,
    Pagination,
    EmptyState,
    SectionFooter
} from "../components";

class News extends React.Component {
    state = {
        news: [],
        loading: true
    };

    componentDidMount() {
        const { fn } = window;

        fn.tokenize("get_news_list").then(token => {
            fn.get("/get_news_list", { params: { token } }).then(o => {
                let state = { loading: false };
                const { data } = o;

                if (_.isArray(data) && data.length > 0) state = { ...state, news: data };
                this.setState(state);
            });
        });
    }

    render() {
        const { settings = {} } = this.props.data;
        const { footnote = {} } = settings;

        return (
            <Global title="Articles">
                <Header title="Articles" />

                <Content footerLeft={footnote.left} footerRight={footnote.right}>
                    <CardContent className="news">
                        <NewsContent state={this.state} settings={settings} />
                    </CardContent>
                    <SectionFooter settings={settings} />
                </Content>
            </Global>
        );
    }
}

class NewsContent extends React.Component {
    state = {
        currentIndex: 0
    };

    _onPagination = currentIndex => this.setState({ currentIndex });

    render() {
        const { fn } = window;

        const { currentIndex = 0 } = this.state;
        const { state, settings } = this.props;
        const { news = [], loading } = state;
        const { news_per_page = 10, news_columns = 2 } = settings;

        if (loading === false) {
            if (_.isArray(news) && news.length > 0) {
                const chunks = _.chunk(news, news_per_page);
                const currentList = _.get(chunks, `[${currentIndex}]`, []);

                return (
                    <React.Fragment>
                        <Grid columns={news_columns} doubling stackable>
                            {currentList.map((item, index) => {
                                const { slug, title, date, cover, day, month, content } = item;
                                const excerpt = _.get(item, "excerpt", fn.truncate(content, 120));
                                const url = `/news/${slug}`;

                                return (
                                    <Grid.Column key={index}>
                                        <Card>
                                            <div className="date">
                                                <div>
                                                    <span className="day">{day}</span>
                                                    <span className="month">
                                                        {fn.truncate(month, 3, false)}
                                                    </span>
                                                </div>
                                            </div>
                                            {cover && <CardCover backgroundImage={cover} />}
                                            <CardContent>
                                                <Link to={url}>
                                                    <Heading as="h5" text={title} subtitle={date} />
                                                </Link>

                                                {excerpt}

                                                <Button
                                                    rounded
                                                    to={url}
                                                    pull="left"
                                                    size="small"
                                                    text="Read More"
                                                    style={{ marginTop: 20 }}
                                                    color="blue"
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid.Column>
                                );
                            })}
                        </Grid>

                        <Divider hidden />
                        <Pagination
                            size="small"
                            length={chunks.length}
                            onClick={this._onPagination}
                            currentIndex={currentIndex}
                        />
                    </React.Fragment>
                );
            } else {
                return (
                    <EmptyState
                        title="No articles have been posted yet"
                        artName="undraw_image_focus_wm20.svg"
                    />
                );
            }
        } else {
            return <Loader active inline="centered" />;
        }
    }
}

const mapStateToProps = state => state;
export default connect(
    mapStateToProps,
    actions
)(News);
