import React from "react";
import PropTypes from "prop-types";
import { Icon, Heading, Markdown } from ".";

class RelLinks extends React.Component {
    render() {
        const { links = [], title } = this.props;

        return (
            <div className="toc">
                <Heading as="h5" text={title || "Table of Contents"} />

                <ul>
                    {links.map((item, i) => {
                        const { active, text, link } = item;
                        const className = active ? "active" : "";
                        const source =
                            typeof link !== "undefined" && link !== ""
                                ? `[${text}](${link})`
                                : text;

                        return (
                            <li key={i} className={className}>
                                <div className="icon">
                                    <Icon name="far chevron-right" />
                                </div>
                                <Markdown inline source={source} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

RelLinks.propTypes = {
    links: PropTypes.array,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    markdown: PropTypes.bool
};

export { RelLinks };
