const CONST = {
    IS_DEV: !process.env.DEV || process.env.DEV === "development",
    API_URL: "https://old.aibim.com/request",
    RECAPTCHA: "6LcdJbMUAAAAAAdlDebdRAXBNd5l-QRTDB7mv2dd",
    POST_CONFIG: {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    },
    R: {
        GET_MENU: "GET_MENU",
        NEW_ALERT: "NEW_ALERT",
        HIDE_ALERT: "HIDE_ALERT",
        ENABLE_ADS: "ENABLE_ADS",
        GET_SETTINGS: "GET_SETTINGS",
        TRUNCATE_ALERT: "TRUNCATE_ALERT",
        SAMPLE_REQUEST: "SAMPLE_REQUEST",
        SET_MENU_ARRAY: "SET_MENU_ARRAY",
        GET_MEMBER_BANKS: "GET_MEMBER_BANKS",
        CLEAR_MENU_ARRAY: "CLEAR_MENU_ARRAY",
        SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
        SET_PROGRESS_BAR: "SET_PROGRESS_BAR",
        GET_USER_DATA: "GET_USER_DATA"
    },

    ALERT_TEMPO: 300, // Default duration for alert animation
    ALERT_DURATION: 5000, // Default time before the alert disappears
    ALERT_ERROR_TEXT: "Sorry, we did something wrong!",

    MAX_ANSWERS: 5,
    MAX_SECTIONS: 5,
    MAX_SEGMENTS: 5,
    MAX_QUESTIONS: 20,
    MAX_DOMAINS: 4,

    SITENAME: "AIBIM",
    PROGRESS_BAR: true,
    PROGRESS_BAR_COLOR: "#064acb",
    CHART_COLORS: {
        GREEN: [
            "#1E3140",
            "#1D4250",
            "#18545E",
            "#11666A",
            "#127972",
            "#218C76",
            "#399F77",
            "#56B174",
            "#76C36F",
            "#9BD369",
            "#C2E264",
            "#EEEF61"
        ],

        RED: [
            "#A00B17",
            "#AC1E1E",
            "#B82D27",
            "#C43C30",
            "#CE4A3A",
            "#D75945",
            "#DE6851",
            "#E5775E",
            "#EA866C",
            "#ED967A",
            "#EFA68A",
            "#EEB79A"
        ],

        PINK: [
            "#880E4F",
            "#AD1457",
            "#C2185B",
            "#D81B60",
            "#E91E63",
            "#EC407A",
            "#F06292",
            "#F48FB1",
            "#F8BBD0",
            "#FCE4EC"
        ],

        HEAT: [
            "#003f5c",
            "#2f4b7c",
            "#665191",
            "#a05195",
            "#d45087",
            "#f95d6a",
            "#ff7c43",
            "#ffa600",
            "#fdd043",
            "#f6e79c"
        ],

        SUNSET: [
            "#212A37",
            "#2F3243",
            "#40394E",
            "#524058",
            "#654760",
            "#794D67",
            "#8E546C",
            "#A25A70",
            "#B66271",
            "#C96A71",
            "#DA7470",
            "#E97F6D"
        ],

        CHEERFUL: [
            "#003f5c",
            "#03A9F4",
            "#00BCD4",
            "#009688",
            "#4CAF50",
            "#8BC34A",
            "#CDDC39",
            "#FFEB3B",
            "#FFC107",
            "#FF9800",
            "#FF5722",
            "#795548"
        ]
    },
    PRODUCT_PHOTO_UPLOAD: false,
    STATES: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sabah",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "WP. Kuala Lumpur"
    ],
    COLORS: {
        RED: "#d34848",
        BLUE: "#6a95fc",
        BLACK: "#141515",
        WHITE: "#fff",
        GREEN: "#2ca777",
        PURPLE: "#85457f",
        YELLOW: "#f8b739",
        MAROON: "#6f2e5b",
        HOTPINK: "#c62783",
        LIGHT_GREY: "#f5f7f8"
    }
};

export default CONST;
