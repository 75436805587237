import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader } from "semantic-ui-react";
import { Link, Button, Heading, EmptyState } from ".";

class MiniNews extends React.Component {
    render() {
        const { news, loading } = this.props;
        const props = _.omit(this.props, ["news", "loading", "className"]);

        if (loading === false) {
            if (_.isArray(news) && news.length > 0) {
                return (
                    <React.Fragment>
                        <div
                            className="aibim mini news scrollable hidden-scrollbar with-gradient"
                            {...props}
                        >
                            {news.map((props, i) => {
                                const { slug, title, date } = props;
                                const url = `/news/${slug}`;

                                return (
                                    <div key={i} className="item">
                                        <Link to={url}>
                                            <Heading
                                                as="h6"
                                                text={title}
                                                color="blue"
                                                subtitle={date}
                                            />
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>

                        <Button
                            to="/news"
                            pull="center"
                            text="More News"
                            size="small"
                            color="blue"
                            circular
                        />
                    </React.Fragment>
                );
            } else {
                return (
                    <div className="aibim mini news" {...props}>
                        <EmptyState
                            as="h5"
                            title="You're all catched up!"
                            artName="undraw_connected_world_wuay.svg"
                            subtitle="We have no news for you today, please check again later."
                        />
                    </div>
                );
            }
        } else {
            return (
                <div style={{ height: 300, position: "relative" }}>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                </div>
            );
        }
    }
}

MiniNews.propTypes = {
    news: PropTypes.array
};

export { MiniNews };
