import _ from "lodash";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Grid, Form, Transition } from "semantic-ui-react";
import { Logo, Icon, Fields, Button, Heading, Message } from "..";

class SectionFooter extends React.Component {
    state = {
        token: "",
        loading: false,
        alertColor: "",
        alertMessage: "",
        enquiry: {
            email: "",
            subject: "",
            message: ""
        }
    };

    componentDidMount() {
        const { fn } = window;

        fn.tokenize("send_email").then(token => this.setState({ token }));
    }

    _onChange = (e, input) => {
        const { enquiry } = this.state;
        const { name, value } = input || e.target;

        if (typeof name !== "undefined" && typeof value !== "undefined") {
            this.setState({
                enquiry: {
                    ...enquiry,
                    [name]: value
                }
            });
        }
    };

    _onSubmit = () => {
        const { fn } = window;
        const { token, enquiry } = this.state;
        const { email } = enquiry;
        const postData = {
            ...enquiry,
            token,
            from: email
        };

        this.setState({ loading: true });
        fn.post("/send_email", postData).then(o => {
            const { data } = o;
            const { success, message } = data;

            if (typeof message !== "undefined" && message !== "") {
                this.setState({
                    alertColor: success === true ? "green" : "red",
                    alertMessage: message,
                    loading: false,
                    enquiry: {
                        email: "",
                        subject: "",
                        message: ""
                    }
                });

                setTimeout(() => {
                    this.setState({
                        alertColor: "",
                        alertMessage: ""
                    });
                }, 5000);
            } else {
                this.setState({ loading: false });
            }
        });
    };

    render() {
        const { CONST } = window;
        const { settings } = this.props;
        const { social = [], contact = {}, enquiry_email = "" } = settings;
        const disabled = !(typeof enquiry_email === "string" && enquiry_email !== "");
        const contactKeys = Object.keys(contact);

        const { loading, enquiry, alertColor, alertMessage } = this.state;
        const { email, subject, message } = enquiry;

        const fields = [
            {
                id: "email",
                name: "email",
                type: "email",
                value: email,
                disabled,
                onChange: this._onChange,
                placeholder: "you@example.com"
            },
            {
                id: "subject",
                name: "subject",
                type: "text",
                value: subject,
                disabled,
                onChange: this._onChange,
                placeholder: "Subject"
            },
            {
                id: "message",
                name: "message",
                type: "textarea",
                rows: 7,
                value: message,
                disabled,
                onChange: this._onChange,
                placeholder: "Type your message here.."
            }
        ];

        return (
            <div className="aibim footer">
                <Grid>
                    <Grid.Column width={7}>
                        <Logo style={{ marginBottom: 20 }} />
                        <div className="description">
                            AIBIM is a dynamic, visible, responsive and effective organisation
                            representing the voice of Islamic Finance industry underpinning the
                            value based intermediation thrust in serving the community.
                        </div>
                        <ul className="social">
                            {_.isArray(social) &&
                                social.length > 0 &&
                                social.map((props, key) => {
                                    let rest = _.omit(props, ["to", "icon", "feature", "label"]);
                                    let { to, icon, feature } = props;

                                    if (feature === "search") {
                                        rest = {
                                            ...rest,
                                            onClick: this._toggleSearch
                                        };
                                    }

                                    return (
                                        <li key={key}>
                                            <a
                                                rel="noopener noreferrer"
                                                href={to}
                                                target="_blank"
                                                {...rest}
                                            >
                                                <Icon name={icon} />
                                            </a>
                                        </li>
                                    );
                                })}
                        </ul>
                        <ul className="list">
                            {contactKeys.length > 0 &&
                                contactKeys.map((key, index) => {
                                    let icon = "question";
                                    const value = contact[key];

                                    switch (key) {
                                        default:
                                            icon = "question";
                                            break;

                                        case "fax":
                                        case "clock":
                                        case "phone":
                                            icon = key;
                                            break;

                                        case "hours":
                                        case "hours2":
                                            icon = "clock";
                                            break;

                                        case "email":
                                            icon = "envelope";
                                            break;
                                    }

                                    return (
                                        <li key={index}>
                                            <div className="icon">
                                                <Icon name={`fal ${icon}`} />
                                            </div>
                                            {value}
                                        </li>
                                    );
                                })}
                        </ul>
                    </Grid.Column>

                    <Grid.Column width={6} floated="right">
                        <Heading as="h5" text="Contact Us" />
                        <p>Feel free to contact us or you may put your inquiry in the box below.</p>

                        {alertMessage && (
                            <Transition
                                visible={alertMessage !== ""}
                                duration={100}
                                animation="scale"
                            >
                                <Message text={alertMessage} color={alertColor} />
                            </Transition>
                        )}

                        <Form loading={loading} onSubmit={this._onSubmit}>
                            <Fields fields={fields} />
                            <Button
                                pull="right"
                                color="blue"
                                text="Submit"
                                onClick={this._onSubmit}
                                loading={loading}
                                disabled={disabled}
                                circular
                            />
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={CONST.RECAPTCHA}
                            />
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

const recaptchaRef = React.createRef();

export { SectionFooter };
