import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import {
    Global,
    Content,
    Header,
    SectionMember,
    SectionFooter,
    SectionPrimary,
    SectionHighlight
} from "../components";

class Account extends React.Component {
    render() {
        const { banks = [], settings = {} } = this.props.data;
        const { footnote = {} } = settings;

        return (
            <Global>
                <Header
                    autoplay
                    featured
                    pauseOnHover
                    settings={settings}
                    bottomOffset={66}
                    autoplayInterval={3000}
                    enableKeyboardControls
                />
                <Content footerLeft={footnote.left} footerRight={footnote.right}>
                    <SectionPrimary settings={settings} />
                    <SectionHighlight settings={settings} />
                    <SectionMember members={banks} />
                    <SectionFooter settings={settings} />
                </Content>
            </Global>
        );
    }
}

const mapStateToProps = state => state;
export default connect(
    mapStateToProps,
    actions
)(Account);
