import _ from "lodash";
import React from "react";
import { Transition } from "semantic-ui-react";
import { Icon, Link, Logo, Field, Markdown } from ".";

class PushMenu extends React.Component {
    _toggleMenu = () => {
        const { toggleMenu } = this.props;
        if (typeof toggleMenu === "function") toggleMenu();
    };

    _onSearch = (e, input) => {
        if (e.key.toLowerCase() === "enter") {
            let { value } = input || e.target;

            if (typeof value !== "undefined" && value !== "") {
                value = value.replace(/ /g, "+");
                window.location = `/search/${value}`;
            }
        }
    };

    render() {
        const { fn } = window;
        const { menu, social, menuOpen } = this.props;

        let array = [];
        if (_.isArray(menu) && menu.length > 0) {
            _.each(menu, props => {
                let { to, label, children = [] } = props;
                let item = { source: `[${label}](${to})` };

                if (children.length > 0) {
                    _.each(children, (child, i) => {
                        const { to, label } = child;
                        children[i] = { ...children[i], source: `[${label}](${to})` };
                    });
                }

                item = { ...item, ...props, children };
                array = [...array, item];
            });
        }

        return (
            <>
                {menuOpen && <div className="aibim menu-overlay" onClick={this._toggleMenu} />}

                <Transition visible={menuOpen} animation="fade right" duration={200}>
                    <div className="aibim push menu">
                        <div className="content">
                            <div className="header">
                                <Logo width={100} />
                                <PushMenu.SocialMedia social={social} />
                                <Field
                                    id="search_input"
                                    type="text"
                                    icon="search"
                                    size="large"
                                    fluid={true}
                                    onKeyDown={this._onSearch}
                                    placeholder="Search.."
                                />
                            </div>

                            <ul>
                                {array &&
                                    array.map((props, key) => {
                                        const {
                                            to,
                                            alt = "image",
                                            image,
                                            source,
                                            label,
                                            children
                                        } = props;
                                        const rest = _.omit(props, [
                                            "to",
                                            "label",
                                            "source",
                                            "children"
                                        ]);

                                        if (typeof source !== "undefined" && source.length > 0) {
                                            return (
                                                <li key={key} onClick={this._toggleMenu}>
                                                    {fn.renderif(
                                                        image,
                                                        <Link to={to}>
                                                            <img src={image} alt={alt} />
                                                        </Link>,
                                                        <Markdown source={source} {...rest} />
                                                    )}
                                                    <PushMenu.Children children={children} />
                                                </li>
                                            );
                                        } else {
                                            return (
                                                <li key={key} onClick={this._toggleMenu}>
                                                    <div>
                                                        {fn.renderif(
                                                            image,
                                                            <img src={image} alt={alt} />,
                                                            label
                                                        )}
                                                    </div>
                                                    <PushMenu.Children children={children} />
                                                </li>
                                            );
                                        }
                                    })}
                            </ul>
                        </div>
                    </div>
                </Transition>
            </>
        );
    }

    static Children(props) {
        const { children = [] } = props;

        if (_.isArray(children) && children.length > 0) {
            return (
                <ul>
                    {children.map((child, i) => {
                        const { source } = child;
                        const rest = _.omit(child, ["to", "label", "source", "children"]);

                        return (
                            <li key={i}>
                                <Markdown source={source} {...rest} />
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            return "";
        }
    }

    static SocialMedia({ social }) {
        if (_.isArray(social) && social.length > 0) {
            return (
                <div className="social">
                    {social.map((item, i) => {
                        let { to, icon, feature } = item;
                        let rest = _.omit(item, ["to", "icon", "feature", "label"]);

                        if (feature === "search") return "";

                        rest = {
                            ...rest,
                            rel: "noopener noreferrer",
                            href: to,
                            target: "_blank"
                        };

                        return (
                            <div className="item" key={i}>
                                <a {...rest}>
                                    <Icon name={icon} />
                                </a>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return "";
        }
    }
}

export { PushMenu };
