import axios from "axios";
import CONST from "../includes/Constants";

export default axios.create({
    withCredentials: true,
    baseURL: CONST.API_URL,
    headers: {
        Authorization: "Bearer " + process.env.REACT_APP_API_KEY
    }
});
